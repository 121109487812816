






































import { signIn } from '@/utils/auth';
import { Vue, Component } from 'vue-property-decorator';
@Component
export default class Login extends Vue {
  public optionClicked(provider: string): void {
    signIn(provider);
  }
}
